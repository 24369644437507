@import './imports.scss';

.sliderContainer {
    width: 100%;
    /* position: relative; */
    /* padding: 0px; */

    opacity: 0.3;
}

.sliderContainer .slick-track {
    display: flex;
    height: 100px;
    align-items: center;
    gap: 40px;
    /* overflow: hidden; */

    @include breakpoint($mobile-bp) {
        gap: 20px;
        height: auto;
    }
}

.sliderContainer .slick-slide {
    max-width: 200px;

    filter: grayscale(1);

    @include breakpoint($mobile-bp) {
        max-width: 200px;
    }
}

/* .sliderContainer::before {
    content: '';
    width: 3000px;
    height: 100%;
    background-color: white;
    position: absolute;
    top: 0;
    left: -1000px;
    z-index: -1;
} */

// @media only screen and (max-width: 600px) {
//     .sliderContainer::before {
//         width: 100%;
//     }

//     .sliderContainer .slick-track {
//         gap: 20px;
//         height: 46px;
//     }

//     .sliderContainer {
//         width: auto;
//     }
// }
