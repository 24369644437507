@import './imports.scss';

.HIW-case {
    display: flex;
    flex-direction: column;
    gap: 40px;

    @include breakpoint($mobile-bp) {
        margin-bottom: 50px;

        min-height: 60vh;
        // align-items: center;
        justify-content: center;
    }
}

.HIW-case-container {
    display: flex;
    padding: 32px 37px 70px 37px;
    align-items: flex-start;
    align-content: flex-start;
    gap: 20px;
    align-self: stretch;
    flex-wrap: wrap;
    border-radius: 20px;
    background: radial-gradient(44.79% 42.28% at 50% 57.72%, rgba(255, 255, 255, 0.47) 0%, #fff 100%);
    /* box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.05); */
    position: relative;

    // width: 70%;

    @include breakpoint($mobile-bp){
        gap: 0;

        
    }
}

.HIW-case-container__pie-chart {
    max-height: 80%;
    align-self: flex-start;
}

.HIW-case .slick-track {
    display: flex;
    // align-items: center;
    gap: 0px;

    // width: 80%;

    @include breakpoint($mobile-bp) {
        align-items: flex-start;
    }
}

.HIW-case .slick-list {
    overflow: hidden;

    // overflow-y: auto;
    width: 100%;
    // height: 500px !important;
}

.HIW-case .slick-slider {
    position: relative;

    // width: 80%;

    display: flex;

    align-items: center;
    align-content: center;
    gap: 20px;
}

.HIW-case-container__features-list {
    display: flex;
    flex-direction: column;

    @include breakpoint($mobile-bp){
        margin: 5px 0;
    }
}

.HIW-case-container__features-list li {
    display: flex;
    align-items: center;
}

.HIW-case-container__features {
    &--container-real {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.HIW-case-container__check-mark {
    min-width: 20px;
    width: 20px;
    height: 20px;
    color: $selected-color;
}

.HIW-case-container__effects {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    &--sm {
        justify-content: flex-start !important;
        align-items: flex-start !important;

        .business-case__effect-title {
            right: -20% !important;
            width: 70% !important;

            @include breakpoint($mobile-bp) {
                width: 250px !important;
            }
        }

        // .business-case__effect-title {
            
        //     @include breakpoint($mobile-bp) {
        //         width: 200px ;
        //     }
        // }
    }
}

.HIW-case-container__results {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 30px;
    /* align-items: flex-start;
    justify-content: flex-start; */
}

.HIW-case-container__effect-title {
    background: $radial-bg;
    border-radius: 10px;
    text-transform: lowercase;
    padding: 20px;
    color: white;
    text-align: center;
    max-width: 80%;
    font-weight: 300;
}

.HIW-case-container__effect-percent {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 20px;
}

.HIW-case-container__effects {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    flex-wrap: nowrap;
    height: 300px;
}

.HIW-chart-container {
    height: 75%;

    @include breakpoint($mobile-bp) {
        height: 150px;
        position: relative;
        right: 20px;
    }
}

.HIW-case .sliderArrowButton {
    // position: absolute;
    // top: 45%;
}

.HIW-case .slick-prev {
    // left: -100px;
    transform: rotate(180deg);

    @include breakpoint($mobile-bp) {
        left: 0;
    }
}
.HIW-case .slick-next {
    // right: -100px;

    @include breakpoint($mobile-bp) {
        right: 0;
    }
}

@include breakpoint($mobile-bp) {
    .HIW-case .slick-dots {
        position: absolute;
        display: flex !important;
        gap: 10px;

        border: none;
        // background-color: #c772ff;

        bottom: -30px;
        margin: 0 auto;
        left: 35%;

        button {
            border: none;
            background-color: rgba($color: $inactive-color, $alpha: 1.0);
            border-radius: 50%;
            font-size: 0;

            width:15px;
            height:15px;
        }

        li.slick-active {
            button {
                background-color: rgba($color: $selected-color, $alpha: 1.0);
            }
        }


    }

    .dots-container{
        width: 100%;
    }
}

.HIW-case .slick-arrow {
    font-size: 50px;
    background-color: #dadada;
    color: white;
    border-radius: 50%;
    padding: 15px;
    width: 50px;
    height: 50px;
    cursor: pointer;
    transition: all ease-in-out 0.3s;

    @include breakpoint($mobile-bp) {
        position: absolute;
        bottom: -35px;

        width: 35px;
        height: 35px;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: rgba($color: #dadada, $alpha: 0.7);
        z-index: 10;
    }
}

.HIW-case .slick-arrow:hover {
    background-color: #c7c7c7;
}

.HIW-effect-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

@media only screen and (max-width: 600px) {
    .HIW-case-container {
        flex-direction: column;
        padding: 20px;
    }

    .HIW-case-container__results {
        display: flex;
        flex-direction: column;

        /* height: 100%; */
    }

    .HIW-case-container__effects {
        gap: 0;
        height: auto;
    }

    .HIW-case {
        gap: 20px;
    }
}
