@import './imports.scss';

.comparison-header {
    text-align: center;

    border-radius: 20px;
    background: radial-gradient(40.89% 38.6% at 50% 61.4%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.34) 100%);
    box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.05);
    position: relative;

    display: flex;
    flex-direction: column;

    gap: 20px;

    padding: 40px 40px;

    margin-bottom: 40px;

    .comparison-vs {
        order: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    .comparison-text{
        order: 2
    }

    @include breakpoint($mobile-bp) {
        h2 {
            line-height: 30px;

            font-size: 20px;
        }

        padding: 70px 25px;

        .comparison-vs {
            order: 1;
        }

        .comparison-text{
            order: 2;
        }

        gap: 40px;
    }
}

.comparison-block {
    display: flex;
    flex-direction: column;
    // gap: 20px;

    & .comparison-row:first-child .comparison-row__plan-item {
        border-radius: 20px 20px 0px 0px;
    }

    & .comparison-row:last-child .comparison-row__plan-item {
        border-radius: 0px 0px 20px 20px;
    }

    @include breakpoint($mobile-bp) {
        gap: 40px;
    }
}

.comparison-row__soft-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.comparison-row__soft-logo {
    width: 150px;
    height: 50px;

    object-fit: contain;
}

.comparison-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    @include breakpoint($mobile-bp) {
        grid-template-rows: min-content 1fr 1fr; /* То же самое для мобильных устройств */
        grid-template-columns: none;
        gap: 0;
    }
}

.comparison-row__plan-item {
    display: flex;

    gap: 20px;

    padding: 10px;

    &--cas-plan {
        background-color: #c5e1d7;
        @include breakpoint($mobile-bp) {
            img {
                height: 30px;
                margin-left: auto;
            }
        }
    }

    &--anaplan {
        @include breakpoint($mobile-bp) {
            background-color: #9bcebc;
        }

        @include breakpoint($mobile-bp) {
            img {
                height: 30px;
                margin-left: auto;
            }
        }
    }

    &:not(:last-child) {
        padding-bottom: 20px;

        @include breakpoint($mobile-bp) {
            padding: 10px;
        }
    }

    @include breakpoint($mobile-bp) {
        border-radius: 0 !important;
        align-items: center;
    }
}

.comparison-description {
    padding-top: 10px;

    @include breakpoint($mobile-bp) {
        padding: 0;
        margin-bottom: 10px;
    }
}

.comparison-icon {
    border-radius: 100%;
    background-color: white;

    min-width: 35px;
    min-height: 35px;

    max-height: 35px;
    max-width: 35px;

    display: flex;
    justify-content: center;
    align-items: center;

    &--red {
        background-color: #e1c5c5;
        color: #cf9b9b;
    }

    &--green {
        background-color: #a4c1b7;
        color: #298968;
    }
}
