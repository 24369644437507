@import './imports.scss';

.galery-container{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.galery-list{
    display: grid;

    grid-template-columns: repeat(3, 1fr);

    gap: 20px;


    img{
        width: 100%;
        cursor: pointer;     
        opacity: .6;
        transition: all 1 ease-in-out;
        
        &:hover{
            opacity: 1;
        }
    }

    @include breakpoint($mobile-bp){
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}


.galery-card{
    background-color: white;
    border-radius: 20px;
    padding:  25px;
    position: relative;
    overflow: hidden;
    

    display: flex;
    flex-direction: column;

    gap: 10px;

    
}

.galery__popup-image{
    width: 100%;
    object-fit: contain;

}

.galery-header{
    text-align: center;

    
    // border-radius: 20px;
    // background: radial-gradient(40.89% 38.6% at 50% 61.4%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.34) 100%);
    // box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.05);
    // position: relative;

    // display: flex;
    // flex-direction: column;

    // gap: 20px;

    // min-height: 30vh;

    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    // text-align: center;

    h2 {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        font-size: 1.5rem;
    }

    @include breakpoint($mobile-bp){
        padding-top: 55px;
    }
}