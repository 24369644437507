@import './imports.scss';

.popup-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    opacity: 0;
    pointer-events: none;
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 0.3s ease-in-out;

    
}

.popup-container--active {
    opacity: 1;
    pointer-events: all;
}

.popup-container__backdrop {
    position: absolute;
    opacity: 0.4;
    background-color: black;
    width: 100%;
    height: 100%;

    @include breakpoint($mobile-bp){
        opacity: .8;
    }
}

.popup-preview-container {
    width: 100%;
    height: auto;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}

.current-popup {
    z-index: 3;

    width: auto;
    height: auto;

    /* width: 60%; */

    max-width: 1130px;

    display: flex;
    justify-content: center;
    align-items: center;

    @include breakpoint($mobile-bp){
        // overflow: hidden;

        max-width: 95%;
    }
}

@media only screen and (max-width: 600px) {
    
}
