@import './imports.scss';

.sliderImagesContainer {
    max-width: 100%;

    max-height: 80vh;

    .slick-slider {
        position: relative;
    }
    .slick-arrow {
        font-size: 40px;
        background-color: #dadada;
        color: white;
        border-radius: 50%;
        padding: 15px;
        width: 30px;
        height: 30px;
        cursor: pointer;
        transition: all ease-in-out 0.3s;

        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;

        position: absolute;
        top: 40%;

        @include breakpoint($mobile-bp) {
            top: 92%;
        }

        &:hover {
            background-color: #c7c7c7;
        }

        &.slick-prev {
            left: 0%;
            transform: rotate(180deg);
        }
        &.slick-next {
            right: 0%;
        }
    }
}

.sliderImagesContainer .slick-track {
    display: flex;
}

.sliderImagesContainer .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.sliderImagesContainer .slick-dots {
    display: flex !important;
    justify-items: center;
    justify-content: center;

    li button {
        content: 'фыв' !important;
        border: 0;
        border-radius: 50%;

        font-size: 0;

        width: 15px;
        height: 15px;

        opacity: 0.6;

        cursor: pointer;

        &:hover {
            opacity: 8;
        }
    }

    li.slick-active button {
        opacity: 1;
    }
}

.sliderImagesContainer .slick-slide {
    height: 100%;
    // min-height: 1px;
}
