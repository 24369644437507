@import './components/imports.scss';

body {
    font-family: 'Cera Pro', sans-serif;

    background: url('./images/triangle.svg') no-repeat left top, linear-gradient(180deg, #f1f1f1 0%, #ededed 100%) no-repeat;
    

    background-size: cover;
    min-height: 100vh;
    margin: 0;

    overflow-x: hidden;
}

html {
    font-size: 16px; /* Размер шрифта корневого элемента */
}

/* body::before{
  position: absolute;
  content: '';
  background-image: url('./images/triangle.svg');
  height: 1000px;
  width: 1000px;
  background-repeat: no-repeat;
} */

@font-face {
    font-family: 'Cera Pro';
    src: local('Cera Pro'), url(./fonts/CeraPro-Thin.woff) format('woff');
    font-weight: 100;

    font-display: swap;
}

@font-face {
    font-family: 'Cera Pro';
    src: local('Cera Pro'), url(./fonts/CeraPro-Light.woff) format('woff');
    font-weight: 200;

    font-display: swap;
}

@font-face {
    font-family: 'Cera Pro';
    src: local('Cera Pro'), url(./fonts/CeraPro-Regular.woff) format('woff');
    font-weight: 300;

    font-display: swap;
}

@font-face {
    font-family: 'Cera Pro';
    src: local('Cera Pro'), url(./fonts/CeraPro-Medium.woff) format('woff');
    font-weight: 500;

    font-display: swap;
}

@font-face {
    font-family: 'Cera Pro';
    src: local('Cera Pro'), url(./fonts/CeraPro-Bold.woff) format('woff');
    font-weight: 700;

    font-display: swap;
}

@font-face {
    font-family: 'Cera Pro';
    src: local('Cera Pro'), url(./fonts/CeraPro-Black.woff) format('woff');
    font-weight: 900;

    font-display: swap;
}

hr {
    border: 1px solid #2b2b2b;
    width: 100%;
    opacity: 0.1;
}
