@import './imports.scss';

.header {
    display: flex;
    padding: 38px 78px;

    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;

    border-radius: 20px;
    background: radial-gradient(40.89% 38.6% at 50% 61.4%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.34) 100%);
    box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.05);

    position: relative;

    height: 60vh;
    min-height: 600px;

    @include breakpoint($mobile-bp){
        // height: 45vh;
        min-height: unset;
    }
}

.header__inner{
    display: flex;
    flex-direction: column;
    gap: 50px;
    /* height: 100%; */

    /* width: 60%; */
}

ul {
    display: grid;
    grid-template-columns: 1fr 1fr;

    padding: 0;
    gap: 10px;

    &.--rows{
        display: flex;
        flex-direction: column;
        font-size: 1.1rem
    }
}

li {
    list-style-type: none;
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 10px;
   
}

.img-point {
    background: url('../images/li.svg') no-repeat;
    background-size: contain;
    min-width: 22px;
    min-height: 22px;
    left: 0;

    position: relative;
    top: 3px;
}

.button-list {
    display: flex;
    gap: 20px;
}

.header-img {
    position: absolute;
    background-image: url('../images/laptop.webp');
    background-size: contain;
    background-repeat: no-repeat;
    height: 800px;
    width: 1000px;
    content: '';
    top: -0px;
    right: -210px;
    pointer-events: none;
}

.home-container {
    display: flex;
    flex-direction: column;
    gap: 95px;
}

@media only screen and (max-width: 600px) {
    .home-container {
        gap: 50px;
    }

    .header {
        padding: 25px;
    }

    .header-img {
        height: 400px;
        width: 1000px;
        top: 0px;
        right: 0px;
    }

    .header__inner{
        width: 100% !important;
        gap: 20px;
    }

    .header__inner ul {
        display: flex;
        flex-direction: column;
    }
}
