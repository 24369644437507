@import './imports.scss';

.analogs-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.comparisons {
    display: grid;
    gap: 20px;

    grid-template-columns: 1fr 1fr;
}

.comparisons__item {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px;
    border-radius: 25px;
    gap: 20px;
}

.comparisons__item > .column {
    display: flex;
    flex-direction: column;
}

.comparisons__item .row:not(:first-child) div:nth-child(even) {
    background: $selected-color;
    color: white;
    padding: 8px 10px;
}
.comparisons__item .row:not(:first-child) div:last-child {
    background: $inactive-color;
    color: white;
    padding: 8px 10px;
}

.comparisons__item .row div:not(:first-child) {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
}


.comparisons__item .row {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    gap: 20px;
    align-items: center;

    font-weight:200;
}

@media only screen and (max-width: 600px) {
    .comparisons {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .row__item--text{
        font-size: 14px;
    }
}
