@import './imports.scss';
.functional-block {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.functional-container {
    display: flex;
    padding: 32px 37px 70px 37px;
    align-items: flex-start;
    align-content: flex-start;
    gap: 20px;
    align-self: stretch;
    flex-wrap: wrap;

    border-radius: 20px;
    background: radial-gradient(44.79% 42.28% at 50% 57.72%, rgba(255, 255, 255, 0.47) 0%, #fff 100%);
    box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.05);

    position: relative;
}

.categories__buttons-list .slick-track {
    display: flex;
    gap: 15px;
}

.functional-container__categories {
    /* overflow: hidden;
    width: 100%; */

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    width: 100%;

    @include breakpoint($mobile-bp) {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 10px !important;
    }
}

.functional-container .header__header{
    @include breakpoint($mobile-bp){
        margin-bottom: 0px !important;
    }
}

.functional-container__categories .btn {
    padding: 10px;
}

.content__illustration {
    flex-shrink: 0;
    border-radius: 29px;
    background: url('../images/screenshots/future.webp'), lightgray 50% / contain no-repeat;
    box-shadow: 0px 4px 11.1px 0px rgba(0, 0, 0, 0.05);
    min-height: 300px;
}

.functional-container__content {
    display: grid;
    gap: 60px;

    grid-template-columns: 1fr 1fr;
}

.content__text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.content__chart {
    position: absolute;
    bottom: -30px;
    right: 0;

    width: 200px;
    height: 200px;
}

@media only screen and (max-width: 600px) {
    .functional-container {
        padding: 25px;
        min-height: none;
    }

    .functional-container__content {
        display: flex;
        flex-direction: column;
    }

    .content__illustration {
        min-height: 200px;
        border-radius: 20px;
    }

    .content__chart {
        bottom: 111px;
        right: -72px;
    }
}
