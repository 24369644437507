@import './imports.scss';

h1,
h2,
h3,
h4,
p {
    margin: 0;
}

h1 {
    font-size: 4.5rem;
    font-weight: 400;
}

h2 {
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 45px;
}

h3 {
    font-weight: 700;
    margin-bottom: 10px;
}
p {
    font-weight: 200;
}

.tp-thin {
    font-weight: 100 !important;
}

.comment {
    font-size: 1.2rem;
    font-weight: 400;
    text-transform: uppercase;
}

@media only screen and (max-width: 600px) {
    h1 {
        font-size: 40px;
    }

    h2 {
        font-size: 27px;
    }

    h3 {
        font-size: 17px;
    }
}

.cl--purple {
    color: $selected-color;
}

.cl--lightblue {
    color: #06c4bf;
}

.tp--bold {
    font-weight: 600;
}

.dark-green {
    color: #0a1d10;
}
.light-green {
    color: #E4F1ED;
}

.t__size-normal{
    font-size: 2rem;
}