@import './imports.scss';

.advantages__block {
    display: flex;
    flex-direction: column;

    gap: 20px;
}

.advantages__container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    overflow: visible;
}

.advantages-regular {
    display: grid;

    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;

    gap: 20px;
}

.advantages-main {
    background-color: white;
    border-radius: 20px;
    padding: 40px 25px;
    overflow: visible;
    position: relative;

    @include breakpoint($mobile-bp){
        padding: 20px 15px;
    }
}

.advantages-regular__item:nth-child(odd) {
    text-align: left;
}
.advantages-regular__item:nth-child(even) {
    text-align: left;
}

.advantages-regular__item {
    background-color: white;
    border-radius: 20px;
    padding: 40px 25px;

    position: relative;
    overflow: hidden;
}

.advantages-regular__item h3 {
    font-size: 30px;
}

.advantages-main {
    display: grid;
    grid-template-columns: 0.6fr 0.4fr;

    overflow: visible;
    position: relative;
    z-index: 1;
}

.advantages-main__images {
    background-image: url('../images/screenshots/Group7.png');
    background-size: contain;
    content: '';
    overflow: visible;

    position: absolute;
    width: 500px;
    height: 300px;
    bottom: -50px;
    right: -120px;

    background-repeat: no-repeat;
}

.background-image {
    position: absolute;
    top: 0;
    font-size: 250px;

    color: black;
    opacity: 0.03;
}

.background-image--left {
    left: 0;
}

.background-image--right {
    right: 0;
}

@media only screen and (max-width: 600px) {
    .advantages-main {
        display: flex;
        flex-direction: column;
    }

    .advantages-regular {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .advantages-regular__item {
        padding: 20px 15px;
        text-align: left !important;
    }

    .advantages__block h2 {
        line-height: 35px;
        margin-bottom: 10px;
    }

    .advantages-main__images {
        display: none;
    }

    .advantages-main ul {
        display: flex;
        flex-direction: column;
    }
}

.fa-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    /* box-sizing: border-box; */
    @include breakpoint($mobile-bp){
        display: flex;
        flex-direction: column;
    }
}

.fa-item {
    background-color: white;
    color: #0a1d10;
    border-radius: 20px;
    padding: 30px 20pt;
    

    transition: all 0.3s ease-in-out;

    transition: box-shadow 1 ease-in-out;
    transform: scale(1);
    /* box-sizing: border-box; */

    box-shadow: 0px 0px 0px rgba(0, 0, 0, .1);
    

    &.fa-item--screenshots-btn{
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        overflow: hidden;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        text-transform: uppercase;
        color: white;
        cursor: pointer;


        h3{
            z-index: 3;
            font-size: 2rem;
        }

        &::after{
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: -1;

            content: '';

            position: absolute;
            pointer-events: none;

            background: linear-gradient(rgba($color: $selected-color, $alpha:.7), rgba($color: $hover-color, $alpha:.4));
            // background-color: rgba($color: #000000, $alpha: 1.0);
            

            backdrop-filter: blur(1.5px);
        }
    }

}

.fa-item:hover {
    transform: scale(1.2);
    background-color: #fdfdfd;
    z-index: 2;

    box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);

    @include breakpoint($mobile-bp){
        transform: scale(1);
    }
}
.fa-item__icon {
    font-size: 30pt;
    margin-bottom: 20px;
}
