@import './imports.scss';

.formDemo{
    color: white;
    padding: 30px 27px;

    background: $radial-bg;
    border-radius: 25px;
    position: relative;
    overflow: hidden;

    /* max-width: 900px; */
}

.formDemo::after{
    content: 'Ф';
    position: absolute;
    top: -80px;
    right: -70px;

    font-size: 300px;
    color: #ececec;

    transform: rotate(45deg);
    pointer-events: none;

    z-index: 2;
}

.formDemo__content{
    display: flex;
    flex-direction: column; 
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;

    width: 70%;
    text-align: left;

    z-index: 3;
    position: relative;
}
.formDemo__text h2{
    margin-bottom: 5px;
}

input {
    border-radius: 10px;
    // border: 1px solid #c9c9c9;
    border: none;
    background: #fff;
    box-shadow: 0px 0px 31px 0px rgba(0, 0, 0, 0.15) inset;
    padding: 12px 26px;
}

.input-list{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px;
}

.input-list input::placeholder{
    opacity: .5;
}

@media only screen and (max-width: 600px) {
    .formDemo{
        padding: 25px;
    }

    .formDemo::after{
        opacity: .2;
    }

    .formDemo__content{
        width: 100%;
    }

    .input-list{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}