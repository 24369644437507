@import './imports.scss';

.reviewContainer {
    border-radius: 20px;
    background: $radial-bg;
    color: white;

    display: grid;
    grid-template-columns: 0.8fr 0.35fr;

    padding: 20px;
    gap: 20px;

    position: relative;
    overflow: hidden;
}

.reviewContainer::before{
    content: 'O';
    position: absolute;
    top: -80px;
    right: -70px;

    font-size: 350px;
    color: #f0f0f0;

    transform: rotate(45deg);
    pointer-events: none;
    
}

.reviewContainer__preview {
    background-size: cover;
    position: relative;

    border-radius: 17px;
    box-shadow: 0px 4px 11.1px 0px rgba($color: #000, $alpha: .3);

    cursor: pointer;
}

.reviewContainer__preview::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;

    background-image: url('../images/play.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 25%;
}


@media only screen and (max-width: 600px) {
    .reviewContainer{
        display: flex;
        flex-direction: column;
    }

    .reviewContainer__preview{
        width: 100%;
        min-height: 150px;
    }

    .reviewContainer::before{
        top: -220px;
        right: -150px;
    
        font-size: 350px;
        color: #f0f0f0;
        opacity: .2;
    }
}
