@import './imports.scss';

.footer-container {
    display: flex;
    gap: 50px;

    padding-top: 60px;
    padding-bottom: 40px;
    align-items: flex-start;

    color: rgb(255, 255, 255);
    font-size: 15px;

    margin-top: auto;

    position: relative;

    z-index: 2;

    @include breakpoint($mobile-bp){
        gap: 25px;
    }
}

.footer-container::before {
    position: absolute;
    width: 300%;
    height: 100%;

    content: '';
    top: 0;
    left: -100%;

    background: $radial-bg;
    z-index: -1;

    pointer-events: none;
}

.footer-container h2{
    line-height: 40px;
    margin-bottom: 10px;
}

.footer-container__footer-menu, .footer-container__phones {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;

    align-items: flex-start;
}

.footer-container__footer-menu a {
    text-decoration: none;
    color: #ebebeb;

    transition: all ease-in-out .3s;
}

.footer-container__footer-menu a:hover {
    color: #d4d4d4;
}

.footer-container > *{
    flex: 1;
}

.footer-container__location{
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    
    
}

.footer__social{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.footer__social > *{ 
    display: flex;
    align-items: center;
    gap: 5px;

    text-align: justify;
}

.whatsapp-link:hover{
    color: rgb(141, 247, 141);
}

.telegram-link:hover{
    color: rgb(150, 174, 253);
}

.instagram-link:hover{
    color: rgb(229, 141, 247);
}

.footer-container__phones{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.footer-container__map{
    border-radius: 15px;
    height: 100%;
}

@media only screen and (max-width: 600px) {
    .footer-container{
        flex-direction: column;
        
    }

    .footer-container::before{
        width: 120%;
        left: -10%;
    }
}