@import './imports.scss';

/* HexGrid.css */

.hex-main {
    display: flex;
    --s: 200px; /* size  */
    --m: 4px; /* margin */
    --f: calc(1.732 * var(--s) + 4 * var(--m) - 1px);

    @include breakpoint($mobile-bp) {
        --s: 120px;

        // margin-bottom: 3rem;
    }
}

.hex-container {
    font-size: 0; /*disable white space between inline block element */
    margin-bottom: 3rem;

    padding-left: 20px;

    @include breakpoint($mobile-bp) {
        padding-left: 0px;

        // height: 150%;

        height: 1200px;
    }
}

.hex-container div:not(.hex-text):not(.hex-text__inner) {
    width: var(--s);
    margin: var(--m);
    height: calc(var(--s) * 1.1547);
    display: inline-block;
    font-size: initial;
    clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
    // background: red;
    margin-bottom: calc(var(--m) - var(--s) * 0.2885);
    color: white;
}

.hex-container::before {
    content: '';
    width: calc(var(--s) / 2 + var(--m));
    float: left;
    height: 120%;
    shape-outside: repeating-linear-gradient(#0000 0 calc(var(--f) - 3px), #000 0 var(--f));
}

.hex-item {
    position: relative;
    cursor: pointer;

    .hex-text {
        position: absolute;
        height: 100%;
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 10px;
        box-sizing: border-box;

        @include breakpoint($mobile-bp) {
            font-size: 0.8rem;
        }
    }
}



.hex-item {
    transition: all 0.3s ease-in-out;
    opacity: 1;
    user-select: none;
}

.hex-item.inActive {
    opacity: 0.3;
}

.hex-item.active:hover {
    opacity: 1;
    scale: 1.3;
    z-index: 3;
}

.cases-hex-details-container {
    border-radius: 20px;
    color: white;
    width: 80%;

    padding: 40px;
}

.cases-hex-details-container ul {
    display: flex;
    flex-direction: column;

    li {
        list-style: circle;
        // padding-left: 30px;
        list-style-type: circle;
        display: list-item;
        position: unset;
        align-items: unset;
        gap: unset;

        position: relative;
        left: 10px;
    }
}

.legend-container {
    display: flex;
    gap: 20px;
    justify-content: center;

    @include breakpoint($mobile-bp){
        flex-direction: column;
    }
}

.legend-item {
    padding: 20px;
    border-radius: 10px;
    color: white;

    @include breakpoint($mobile-bp){
        text-align: center;
    }

}
