@import './components/imports.scss';

:root {
    --mobile-version-max-width: 600px;
}

.App {
    min-height: 100vh;
}

.container {
    display: flex;
    flex-direction: column;
    width: 1200px;
    margin: 0 auto;

    gap: 50px;
    min-height: 100vh;

    // padding-top: 100px;
}

.btn {
    display: flex;
    padding: 14px 79px;
    justify-content: center;
    align-items: center;
    gap: 30px;
    border-radius: 13px;
    border: 0;

    cursor: pointer;

    border-radius: 13px;
    border: 1px solid #dadada;
    background: radial-gradient(73.48% 69.37% at 50% 61.4%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.49) 100%);

    transition: all ease-in-out 0.2s;

    @include breakpoint($mobile-bp){
        font-size: 15px;
    }
}

.btn--white {
    background: radial-gradient(73.48% 69.37% at 50% 61.4%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.49) 100%);
}
.btn--white:hover {
    background: $radial-bg;
    border: 1px solid white;
    color: white;
}

.btn--light-blue {
    background: $selected-color;
    color: white;
    border: none;
}
.btn--light-blue:hover {
    background: $radial-bg;
}

.btn--purple {
    background: $selected-color;
    color: white;
    border: none;
}

.btn--light-purple {
    background: $inactive-color;
    border: none;
}

.btn--light-purple:hover {
    background: $hover-color;
    color: white;
}

.btn--sm {
    padding: 15px 30px;
}

a {
    text-decoration: none;
    color: inherit;

    transition: all ease-in-out 0.2s;
}

.a--purple {
    color: #0a1d10;
}
.a--purple:hover {
    color: #298968;
}

.a--black {
    color: #0a1d10;
}
.a--black:hover {
    color: #298968;
}

.link-demo {
    cursor: pointer;
    margin-left: auto;

    position: relative;

    @include breakpoint($mobile-bp){
        margin-left: 0;
    }
}

.link-demo:hover {
    color: $hover-color;
}

.link-demo::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    transform: scale(0.9) translateZ(0);
    filter: blur(15px);
    background: linear-gradient(to left, #1f5f33, #298968, #e4f1ed, #e4f1ed, #298968, #e4f1ed, #24693a, #298968, #e4f1ed);

    background-size: 200% 200%;
    animation: animateGlow 1.25s linear infinite;
    opacity: 0.7;
}

@keyframes animateGlow {
    0% {
        background-position: 0% 50%;
    }
    100% {
        background-position: 200% 50%;
    }
}

@media only screen and (max-width: 600px) {
    .btn--sm {
        padding: 14px 24px;
        text-wrap: nowrap;
    }

    .container {
        display: flex;
        flex-direction: column;
        width: 98%;
        margin: 0 auto;

        gap: 50px;
        min-height: 100vh;

        padding-top: 20px;
    }

    .btn {
        padding: 15px 60px;
        min-width: 125px;
    }

    .business-cases-header .tagButtons .btn {
        padding: 15px 20px;
        min-width: 75px !important;
    }

    .btn--sm {
        padding: 10px 30px;
    }

    .link-demo::after {
        display: none;
    }

    .App {
        overflow: hidden;
    }
}
