@import './imports.scss';

.extractor-table {
    display: flex;
    gap: 40px;

    position: relative;
    user-select: none;

    @include breakpoint($mobile-bp) {
        flex-direction: column;

        display: none;
    }
}

.extractor-table__section {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.extractor-table__section h4 {
    text-align: center;
    text-transform: uppercase;

    font-size: 0.9rem;
    opacity: 0.3 !important;

    transition: all 0.3s ease-in-out;
}

.extractor-table__section h4.hover-active {
    opacity: 1 !important;
    transform: scale(1.1);
}

.extractor-table__subsection {
    cursor: pointer;
    border-radius: 20px;

    padding: 20px;

    background-color: white;
    border-radius: 20px;
    padding: 40px 25px;
    position: relative;
    overflow: hidden;

    opacity: 0.9;

    transform: scale(1);
    transition: all 0.3s ease-in-out;
    z-index: 3;

    position: relative;

    &:hover {
        transform: scale(1.1);
        box-shadow: 0 0 20px rgba($color: #000000, $alpha: 0.1);

        opacity: 1 !important;
    }
}

.extractor-table__line {
    z-index: 1;
}

.extractor-table__section:last-of-type > * {
    // background-color: red !important;
    opacity: 1 !important;
}

.IC-block {
    background-color: rgba(255, 222, 0, 0.5) !important;
    // color: white;

    font-size: 1.2rem;

    &:hover {
        background-color: rgba(255, 222, 0, 1) !important;
    }

    &:hover img {
        opacity: 1;
    }
}

.IC-block img {
    transition: all 0.3s ease-in-out;
    opacity: 0.5;
}

.img-bg {
    position: absolute;
    transform: rotate(15deg);
    right: 0;

    position: absolute;
    top: -20px;
    font-size: 250px;
    color: black;
    // opacity: 0.3;

    height: 150px;
    width: 200px;

    color: white;
    z-index: -1;

    object-fit: contain;
}

.subsection-name {
    z-index: 5;
}

.extractor-block {
    background: linear-gradient(to bottom right, #298968, #a4c1b7);
}
.extractor-block:hover {
    color: white;

    // font-size: 1.2rem;
}

.extractor-table__subsection.default {
    opacity: 0.2;
}

.slider-extractor-adv .slick-track {
    display: flex;
    // height: 300px;
    // justify-content: center;
}

.slider-extractor-adv .slick-slide {
    width: 100px;

    // padding: 0 30px;
    padding-right: 30px;
}

.slider-extractor-adv .slick-list {
    overflow: hidden;
}

.slider-extractor-adv__image-container {
    background-color: #c772ff;

    border-radius: 60px;

    width: 60px;
    height: 60px;

    padding: 5px;

    justify-content: center;
    align-items: center;

    margin-right: 10px;
}

.slider-extractor-adv__advantage-container {
    display: flex !important;
}

.slider-extractor-adv__image {
    height: 100%;
}

.extractor-table-container {
    height: 90vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;

    min-height: 700px;

    gap: 40px;

    @include breakpoint($mobile-bp) {
        height: auto;
    }
}

.extractor-height-container {
    min-height: 500px;
    height: 80vh;
    background: radial-gradient(circle closest-side at 20% 43%, rgba(11, 233, 227, 0.5) 0%, rgba(11, 233, 227, 0) 100%),
        radial-gradient(circle closest-side at 70% 55%, rgba(186, 80, 255, 0.3) 0%, rgba(186, 80, 255, 0) 100%);
    // background: radial-gradient(circle closest-side at 80% 80%, rgba(186,80,255,.7) 0%, rgba(186,80,255,0) 100%);
    position: relative;

    &::before {
        position: absolute;
        content: '';
        width: 400%;
        height: 100%;
        left: -100%;
        top: 0;

        background: linear-gradient(to right, #ebf4f4, #ebf4f4);
        z-index: -1;
    }
}

.extractor-table-description {
    width: 70%;
    margin: 0 auto;

    @include breakpoint($mobile-bp) {
        width: 100%;
    }
}

.extractor-dwh-container {
    display: flex;
    flex-direction: column;

    border-radius: 25px;
    overflow: hidden;

    box-shadow: 0 0 50px rgba($color: #000000, $alpha: 0.1);
}

.dwh-header {
    @include breakpoint($mobile-bp) {
        padding: 19px;
    }
    padding: 40px;
    background: radial-gradient(40.89% 38.6% at 50% 61.4%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.34) 100%);
}

.dwh-description {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    padding: 40px 40px;

    background: $radial-bg;
    color: white;

    @include breakpoint($mobile-bp) {
        display: flex;
        flex-direction: column;
        gap: 20px;

        padding: 19px;
    }
}

.dwh-descriptor__card {
    display: flex;
    flex-direction: column;

    justify-content: flex-start;
    align-items: center;

    text-align: center;

    padding: 0 15%;

    // width: 70%;

    &:first-child {
        padding: 0 5%;
    }

    @include breakpoint($mobile-bp) {
        padding: 0;
        display: grid;
        grid-template-areas:
            'item4  item5'
            'item4  item6';

        &:first-child {
            padding: 0;
        }
    }
}

.item4 {
    grid-area: item4;

    @include breakpoint($mobile-bp) {
        margin-right: 10px;
    }
}
.item5 {
    grid-area: item5;

    @include breakpoint($mobile-bp) {
        text-transform: uppercase;
        font-size: 1.3rem;
    }
}
.item6 {
    grid-area: item6;
}

.dwh-descriptor__card-icon {
    font-size: 4rem;

    @include breakpoint($mobile-bp) {
        font-size: 3rem;
    }
}

.extractor-ic-container {
    min-height: 750px;
    height: 80vh;

    @include breakpoint($mobile-bp) {
        height: auto;
    }
}

.extractor-ic-li {
    font-weight: 200;
    font-size: 1rem;
}

.extractor-ic-container .advantages-regular {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    gap: 10px; /* Пространство между элементами */
    grid-template-areas:
        'item1  item2'
        'item1  item3';

    align-items: flex-start;

    @include breakpoint($mobile-bp) {
        display: flex;
        flex-direction: column;
    }
}

.item1 {
    grid-area: item1;

    svg {
        opacity: 0.05;
        font-size: 340px;
    }
}

.item2 {
    grid-area: item2;
}

.item3 {
    grid-area: item3;
    text-align: left;
}

.extractor.fa-container {
    @include breakpoint($mobile-bp) {
        display: flex;
        flex-direction: column;
    }
}

.extractor-mobile-etl__section {
    display: flex;
    flex-direction: column;
    gap: 10px;

    background-color: white;
    color: #0a1d10;
    border-radius: 30px;
    padding: 30px 20pt;
    transition: all 0.3s ease-in-out;
    transition: box-shadow 1 ease-in-out;
    transform: scale(1);
    /* box-sizing: border-box; */
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);

    text-align: center;
}

.extractor-mobile-etl {
    display: flex;
    flex-direction: column;
    gap: 20px;

    position: relative;

    .img-bg {
        top: 0px;
    }
}

.extractor-mobile-etl__mobile {
    position: absolute;
    bottom: -18px;
    height: 18px;
    object-fit: contain;
    left: 48%;

    opacity: 0.1;
}

.extractor-mobile-etl__section h4 {
    font-size: 1.3rem;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.extractor-mobile-etl__extractor-block {
    background: linear-gradient(to bottom right, #298968, #a4c1b7);
}

.extractor-mobile-etl__IC-block {
    background-color: rgba(255, 221, 0, 0.5) !important;
}

.business-case__video-review {
    @include breakpoint($mobile-bp) {
        margin-bottom: 20px;
        height: 250px;
    }
}
