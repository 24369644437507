@import './imports.scss';

.tagButtons {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.tags-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.tag {
    display: flex;
    padding: 5px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    background: $selected-color;

    font-weight: 100;
    font-size: 14px;
    color: white;
}

.tag--stroke {
    background: none;
    color: $selected-color;
    border: 1px solid $selected-color;
    font-weight: 300;
}

.business-case {
    display: flex;
    padding: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;

    border-radius: 20px;
    background: url('../images/backgrounds/1.webp'), lightgray 50% / cover no-repeat;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
}
.business-case h3 {
    font-size: 24px;
}

.business-cases__list {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
}

@media only screen and (max-width: 600px) {
    .business-cases__list {
        display: flex;
        flex-direction: column;
    }
}

.header__small-text {
    font-size: 14px;
}

.business-case__header-text {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.business-case__description {
    display: flex;
    flex-direction: column;
    gap: 10px;

    align-items: flex-start;
}

.presentation-container {
    gap: 20px;
    padding: 38px 78px;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.05);
}

.presentation-container__inner {
    display: grid;

    grid-template-columns: 1fr 1fr;
}

.presentation__buttons {
    display: flex;
    gap: 10px;
}

.presentation__info {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.presentation-preview {
    max-width: 100%; /* Гарантирует, что изображение не будет шире своего контейнера */
}

.business-case__full-info {
    padding: 38px 78px;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.05);

    @include breakpoint($mobile-bp) {
        padding: 20px;
    }
}

.business-case__full-info ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    margin-bottom: 15px;
}

.business-case__full-info li {
    font-weight: 200;
    padding-left: 23px;
    position: relative;

    display: flex;
    gap: 10px;
    align-content: center;
}

.business-case__full-info li::before {
    position: absolute;
    top: 4px;
    left: 0;
    content: '';

    width: 13px;
    height: 13px;

    border-radius: 50%;

    background-color: $selected-color;

    @include breakpoint($mobile-bp) {
        width: 9px;
        top: 10px;
        height: 9px;
    }
}

.business-case__full-info p {
    margin-bottom: 10px;
}

.business-case__full-info:not(.business-case__full-info--real) p:has(+ ul) {
    font-weight: 500;
}

.business-case__header-img {
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    height: 450px;
    width: 750px;
    content: '';
    top: -60px;
    right: -190px;
    pointer-events: none;

    transform: rotate(10deg);
}

.home-container__header {
    display: grid;
    grid-template-columns: 2fr 1fr;

    @include breakpoint($mobile-bp) {
        grid-template-columns: none;
        display: flex;
        flex-direction: column;
    }

    &--home-container__header {
        @include breakpoint($mobile-bp) {
            height: auto !important;
        }
    }
}

.home-container__header.header {
    align-items: center;
    gap: 30px;

    grid-template-columns: 1fr 1fr;

    @include breakpoint($mobile-bp) {
        align-items: flex-start;
    }
}

.business-case__effect-title {
    text-align: center;
}

.business-case__effect-title span {
    color: #832ae9;
}

.business-case__effects.HIW-case-container__effects {
    position: relative;
    /* height: inherit; */

    gap: 0;
}

.business-case__effects.HIW-case-container__effects .business-case__effect-title {
    position: absolute;
    top: 0;
    right: -90%;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.05);
    padding: 10px;
    width: 100%;

    @include breakpoint($mobile-bp) {
        width: 200px;
        left: 100px;
    }
}

.business-case__effects.HIW-case-container__effects img {
    @include breakpoint($mobile-bp) {
        width: 100px !important;
    }
}

.business-case-preview__header {
    width: 100%;
    /* display: flex; */
}

.business-case-preview__header h3 {
    display: inline;
}

.business-case-preview__header span{
    display: block;
}

.business-case-type--real {
    // color: green;
    font-weight: 300;
    margin-bottom: 10px;
}

.business-case-type--demo {
    color: red;
}

/* real cases */
.business-case__full-info--real h2 {
    margin-bottom: 10px;
}

.business-case__full-info--real ul.--icons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    text-align: center;

    align-items: flex-start;
    /* justify-content: space-between; */
}

.business-case__full-info--real ul.--icons.--flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.business-case__full-info--real ul.--icons.--flex > li {
    flex: 1;
}
.business-case__full-info--real ul.--icons.--flex img {
    width: 100%;
}

.business-case__full-info--real ul.--icons li {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 0px;

    font-size: 14px;
    font-weight: 100;
}
.business-case__full-info--real ul.--icons li img {
    max-width: 150px;
}
.business-case__full-info--real ul.--icons :not(.flex) li p {
    width: 70%;
}

.business-case__full-info--real ul.--icons li {
    transition: all ease-in-out 0.3s;
    opacity: 0.3;
}

.business-case__full-info--real ul.--icons.--no-animation li {
    animation: none !important;
    opacity: 1;
}

.business-case__full-info--real ul.--icons li:nth-child(1) {
    animation: fade 2s ease-in-out infinite;
}
.business-case__full-info--real ul.--icons li:nth-child(2) {
    animation: fade 3s ease-in-out infinite;
}
.business-case__full-info--real ul.--icons li:nth-child(3) {
    animation: fade 4s ease-in-out infinite;
}
.business-case__full-info--real ul.--icons li:nth-child(4) {
    animation: fade 5s ease-in-out infinite;
}
.business-case__full-info--real ul.--icons li:nth-child(5) {
    animation: fade 6s ease-in-out infinite;
}

.business-case__full-info--real ul.--icons li:hover {
    transform: scale(1.1);
    transform: translateY(10px);
}

.business-case__full-info--real ul.--none li::before {
    width: 0;
    height: 0;
    display: none;
}

.business-case__full-info--real ul.--none li {
    padding: 0;
}

h2 img {
    max-height: 42px;
}

@keyframes fade {
    0%,
    100% {
        opacity: 0.3;
    }
    50% {
        opacity: 1;
    }
}

.item {
    width: 56%;
    overflow: visible;
    stroke: red;
    stroke-width: 2;
    stroke-linejoin: round;
    stroke-linecap: round;
}

.box {
    background: none;
    height: 48px;
}

.header--with-icon {
    display: flex;
    gap: 20px;
    align-items: center;
}

.screenshot-list {
    display: flex;
    margin-top: 20px;
    position: relative;

    &::before {
        content: 'Скриншоты';
        position: absolute;
        width: 60%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
        font-size: 2rem;
        opacity: 0.3;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;

        text-transform: uppercase;
    }
}

.screenshot-list img:nth-child(1) {
    width: 60%;
    object-fit: contain;

    z-index: 1;

    transition: all 0.3s ease-in-out;
    transform: scale(1);
    cursor: pointer;
    opacity: 0.8;

    border-radius: 25px;

    box-shadow: 0 0 30px rgba($color: #000000, $alpha: 0.1);
}
.screenshot-list img:nth-child(1):hover {
    transform: scale(1.2);
    opacity: 1;
    box-shadow: 0px 4px 40.1px 0px rgba(0, 0, 0, 0.1); 

    z-index: 3;
}

.screenshot-list img:nth-child(2) {
    width: 40%;
    object-fit: contain;

    position: relative;
    right: 15%;
    opacity: 0.2;
}
.screenshot-list img:nth-child(3) {
    width: 20%;
    object-fit: contain;

    position: relative;
    right: 15%;
    opacity: 0.2;
}

.slider-icons {
    width: 48px;
    height: 48px;
}

.slider-icons .slick-track {
    display: flex;
}

.slider-icon {
    width: 48px;
    height: 48px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.slider-icon--logo {
    background-image: url(../images/logo.svg);
}

.slider-icon--oneC {
    background-image: url(../images/oneC.svg);
}

.slider-AB {
    position: relative;

    display: flex;
    flex-direction: column-reverse;
}

.slider-AB .slick-list {
    overflow: hidden;
}

.slider-AB .slick-track {
    display: flex;
    /* gap: 40px; */
    align-items: flex-start;
}

.stage {
    background: linear-gradient(#36d8d3, #47d6d1);
    padding: 20px;
    box-sizing: border-box;
    border-radius: 20px;

    opacity: 0.3;
    transition: all 0.3s ease-in-out;

    cursor: pointer;
    color: #06c4bf;
    user-select: none;
    transform: scale(0.9) translateY(-5%);
}

.slider-AB .slick-cloned .stage {
    background: #e9e9e9;
    color: #e9e9e9;
}

.slider-AB .slick-slide:not(.slick-current) .stage {
    position: relative;
    max-height: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

/* .slider-AB .slick-active:not(.slick-current)::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 30px;

    bottom: 0;
    left: 0;
} */

.slider-AB .slick-current .stage {
    color: white;
    background: linear-gradient(#06c4bf, #2edad4);
    opacity: 1;
    transform: scale(1) translateY(0);
}

.slider-AB .slider-AB .slick-dots {
    /* position: absolute; */
    top: 0;
    display: flex !important;
    flex-direction: row;
    width: 100%;
    gap: 0;
    height: 7px;
    border-radius: 5px;
    overflow: hidden;

    margin-bottom: 10px;
    /* justify-content: space-between; */
}
.slider-AB .slick-dots li {
    padding-left: 0;
    flex: 1;

    width: 100%;

    transition: all 0.4s ease-in-out;
}
.slider-AB.slick-dots li::before {
    display: none;
}

.slider-AB .slick-dots li button {
    content: '';
    width: 100%;
    outline: none;
    border: none;
    background-color: rgb(90, 207, 203);

    /* padding: 10px 0; */
    height: 100%;
}

.slider-AB .slick-dots li {
    opacity: 1;
}

.slider-AB .slick-dots .slick-active {
    opacity: 1;
    height: auto;
}

.slider-AB .slick-dots .slick-active ~ li {
    opacity: 0.3;
}

.business-case__company-logo {
    height: 75px;
    width: auto;

    margin-bottom: 10px;
    object-fit: contain;
}

.header__inner--business-case {
    height: 100%;
    justify-content: space-around;
}

.home-container__header--business-case-item {
    height: 60vh;

    min-height: 600px;
}

.business-cases-header {
    height: 60vh;

    min-height: 600px;

    @include breakpoint($mobile-bp){
        height: unset;
        min-height: unset;
        margin-top: 40px;
    }
}


.HIW-case .slick-slide{
    height: 100%;
}

.HIW-case .slick-list{
    height: 100%;
}