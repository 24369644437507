@import './imports.scss';

nav {
    display: flex;
    gap: 50px;

    padding: 25px 61px;
    align-items: center;

    border-radius: 0px 0px 30px 30px;
    background: radial-gradient(40.89% 38.6% at 50% 61.4%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.49) 100%);

    box-shadow: 0px 4px 14.3px 0px rgba(0, 0, 0, 0.03);
    backdrop-filter: blur(20px);

    color: #232323;
    font-size: 15px;

    position: fixed;
    top: 0;
    z-index: 9999;

    // background-color: white;
    width: 1200px;

    box-sizing: border-box;

    background: radial-gradient(40.89% 38.6% at 50% 61.4%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.49) 100%);

    @include breakpoint($mobile-bp) {
        // position: fixed;
        flex-direction: column;
        justify-content: space-between;
        // height: 100%;
        position: relative;

        width: 100%;
        right: 0;

        margin: auto 0;

        gap: 70px;
        font-weight: 300;

        // background: rgba($color: #ffffff, $alpha: .3);
        background: none;
        color: white ;
        backdrop-filter: none;
        box-shadow: 0px 4px 14.3px 0px rgba(0, 0, 0, 0.0);
        // backdrop-filter: blur(10px);

        font-size: 24px;
        text-align: center;

        // padding: 20px;
        box-sizing: border-box;
        z-index: 100;
        // padding: 40px 0;
    }
}

.open-menu-button {
    z-index: 10;
    position: fixed;

    right: 20px;
    top: 20px;

    background-color: #0BE9E3;
    padding: 10px;
    border-radius: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;

    color: white;
}

.activeLink {
    color: $selected-color;
    font-weight: 600;
}

.link {
    color: #232323;
    font-weight: 200;
    line-height: normal;
    text-decoration: none;
    transition: all ease-in-out 0.2s;
}
.activeLink, .link{
    @include breakpoint($mobile-bp){
        color: white;
    }
}


.link:hover{
    color: $selected-color;
}

:root {
    --mobile-version-max-width: 600px;
}

@media only screen and (max-width: 600px) {
    nav {
        padding: 13px 21px;
        gap: 20px;
        border-radius: 0px 0px 10px 10px;
    }

    
}


// .sticky-menu{
//     position: fixed;
//     top: 0;
//     z-index: 9999;

//     // background-color: white;
//     width: 1200px;

//     box-sizing: border-box;

//     background: radial-gradient(40.89% 38.6% at 50% 61.4%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.49) 100%);
// }

// @include breakpoint($mobile-bp){
//     .sticky-menu{

//     }
// }

.menu-fake{
    content: '';
    width: 100%;
    height: 69px;

    @include breakpoint($mobile-bp){
        display: none;
    }
}

// nav{
//     position: fixed;
//     top: 0;
//     z-index: 9999;

//     // background-color: white;
//     width: 1200px;

//     box-sizing: border-box;

//     background: radial-gradient(40.89% 38.6% at 50% 61.4%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.49) 100%);
// }